import { http } from '@/http/axios.js'
// 列表
export function getListAPI(params) {
  return http({
    url: '/company/register-dispute/list',
    method: 'get',
    params
  })
}

export function detailAPI(id) {
  return http({
    url: '/company/register-dispute/detail',
    method: 'get',
    params: { id }
  })
}
export function delAPI(id) {
  return http({
    url: '/company/register-dispute/del',
    method: 'post',
    params: { id }
  })
}
