<template>
  <div class="wrapper" style="width: 100%">
    <admin-title :title="$route.meta.name"></admin-title>
    <div class="btn-back">
      <div></div>
      <el-button type="info" @click="$router.back()" size="mini">返回</el-button>
    </div>
    <el-descriptions style="margin-bottom: 20px" direction="vertical" :column="4" border>
      <el-descriptions-item label="组织名称" :span="1" label-class-name="my-label">{{ detail.check_organization_name }}</el-descriptions-item>
      <el-descriptions-item label="排查日期" :span="1" label-class-name="my-label">{{ detail.check_date }}</el-descriptions-item>
      <el-descriptions-item label="排查地点" :span="1" label-class-name="my-label">{{ detail.check_address }}</el-descriptions-item>
      <el-descriptions-item label="排查内容" :span="1" label-class-name="my-label">{{ detail.check_content }}</el-descriptions-item>
      <el-descriptions-item label="类型名称" :span="1" label-class-name="my-label">{{ detail.dispute_type_name }}</el-descriptions-item>
      <el-descriptions-item label="纠纷类容" :span="1" label-class-name="my-label">{{ detail.dispute_content }}</el-descriptions-item>
      <el-descriptions-item label="纠纷结果" :span="1" label-class-name="my-label">{{ detail.dispute_result }}</el-descriptions-item>
    </el-descriptions>
  </div>
</template>

<script>
import { detailAPI } from './api'
export default {
  name: 'Detail',

  data() {
    return {
      detail: {}
    }
  },

  mounted() {
    this.getDetail()
  },

  methods: {
    goBack() {
      this.$router.back()
    },

    async getDetail() {
      this.detail = await detailAPI(this.$route.params.id)
    }
  }
}
</script>

<style scoped lang="scss">
.wrapper {
  .btn-back {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
  }
  .el-descriptions {
    .my-label {
      color: #1a1e25;
      background: #fafafa;
    }
    .my-content {
      .handle {
        display: flex;
        justify-content: space-between;
        & > span {
          &:first-child {
            margin-right: 20px;
            flex: 1;
          }
        }
      }
    }
  }
}
</style>
